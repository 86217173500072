import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import styled, { ThemeProvider } from 'styled-components';
import Img from 'gatsby-image';
import { cwTheme } from '../theme/theme.js';
import SignUpForm from '../components/SignUpForm';
import Button from '../components/Button';
import Video from '../components/Video';

const PostSection = styled.section`

`;

const MetaWrapper = styled.div`
	display: flex;
	max-width: 75em;
	margin: 0 auto;

	width: 100%;
	flex-direction: column;
	justify-content: center;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		flex-direction: column;
	}
`;

const RelatedSection = styled.section`
	text-align: center;
	display: flex;
	flex-direction: column;
	padding-top: 3rem;

`;

const RelatedHeader = styled.h6`
	color: ${cwTheme.secondaryBlue};
	margin-top: 0;
	margin-bottom: .5rem;
	font-size: .8em;
`;

const RelatedWrapper = styled.div`
	display: ${(props) => (props.display ? 'flex' : 'none')};
	flex-direction: row;
	
	flex-wrap: wrap;
	justify-content: center;
	// @media (min-width: ${cwTheme.mediumBreakPoint}) {
	// 	flex-direction: row;
	// }
`;

const RelatedLink = styled(Link)`
	color: ${cwTheme.black};
	text-decoration: none;
	font-weight: 600;
	padding: 1rem;
	margin: 1rem;

	max-width: 200px;
	width: 100%;
	border-bottom: ${cwTheme.border};
	border: ${cwTheme.border};
	border-radius: ${cwTheme.borderRadius};
	box-shadow: ${cwTheme.boxShadow};
	&:hover {
		color: ${cwTheme.blackHover};
	}

	div {
		margin: -1rem -1rem .25rem -1rem;
	}
`;

const PostDescription = styled.div`
	flex: 7;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const PostWrapper = styled.div`
	padding: 1rem;
	max-width: 600px;
	margin: 0 auto;
	width: 100%;
	box-sizing: border-box;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		padding: 1.5rem 1rem;
	}
`;

const BackToAll = styled.div`
	flex: 2;
	padding: 3rem 0;
	flex-direction: column;
	display: none;
	@media (min-width: ${cwTheme.mediumBreakPoint}) {
		display: flex;
	}
	a {
		align-self: center;
	}
`;



const PostContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	box-sizing: content-box;
`;

const Post = styled.div``;

const PostTitle = styled.h3`
	
	line-height: 1.2em;
	margin-top 0em;
	margin-bottom: .25em;

	
`;

const TagContainer = styled.div`
	margin: 0 auto .5rem auto;
	width: 100%;
	justify-content: left;
	display: flex;
	flex-wrap: wrap;

	a {
		background-color: ${cwTheme.secondaryBlue};
		padding: 2px 8px;
		border-radius: 16px;
		text-decoration: none;
		color: white;
		font-size: 12px;
		margin-right: 5px;
		margin-bottom: 5px;
	}

	a:hover {
		background-color: ${cwTheme.lightBlue};
	}
`;

const PostBody = styled.div`
	width: 100%;
	font-family: ${cwTheme.sansSerif} p {
		font-family: 'Merriweather', serif;
		font-weight: 400;
	}

	img {
		max-width: 100%;
		margin: 2rem 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: ${cwTheme.sansSerif};
	}
`;

const PreviousLink = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;

  text-decoration: none;
  color: ${cwTheme.black};
  margin-right: 1rem;
  font-weight: 600;
  
  max-width: 300px;
  text-align: right;
  &:hover {
	color: ${cwTheme.blackHover};
  }

  h6{
	   margin-top: 0;
	   font-weight: 500;
  }

  span {
	font-size: .8em;
	color: ${cwTheme.secondaryBlue};
  }
  
`;

const NextLink = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;
 
  text-decoration: none;
  color: ${cwTheme.black};
  margin-left: 1rem;
  font-weight: 600;
  
  text-align: left;
  max-width: 300px;
  &:hover {
	color: ${cwTheme.blackHover};
  }

  display: flex;
  flex-direction: column;
  align-content: space-between;
  h6{
	margin-top: 0;
	font-weight: 500;
  }
  span {
	font-size: .8em;
	color: ${cwTheme.secondaryBlue};
  }
	
`;

const PostLinks = styled.div`
	text-align: center;
	display: flex;
	justify-content: center;
`;

const PostVideo = styled.div`
	width: 100%;
	background-color: #f3f3f3;
`;

const VideoWrapper = styled.div`
	max-width: 750px;
	margin: 0 auto;
	width: 100%;
	@media (min-width: ${cwTheme.xLargeBreakPoint}) {
		max-width: 1000px;
	}
`;

const VideoLength = styled.span`
	color: ${cwTheme.blackHover};
	font-size: .8em;
	margin-bottom: 5px;
	display: block;
`;

function videoLengthCalc(number) {
	var message;
	number > 1 ? (message = number + ' minutes') : (message = number + ' minute');
	return message;
}

export default ({ data, pageContext }) => {
	const post = data.contentfulOnDemand;

	const relatedOnDemand = data.allContentfulOnDemand.edges;

	console.log('These are my realted ' + pageContext.related);

	const previous = pageContext.prev;
	const next = pageContext.next;
	const { basePath } = pageContext;

	const { title, description, publishDate } = data.contentfulOnDemand;
	return (
		<ThemeProvider theme={{ mode: 'light' }}>
			<Layout>
				<SEO title={title} description={description.childMarkdownRemark.excerpt} />

				<PostSection>
					<PostContainer>
						<Post>
							<PostVideo>
								<VideoWrapper>
									<Video title={post.title} videoSrcUrl={post.videoEmbedUrl} />
								</VideoWrapper>
							</PostVideo>
							<MetaWrapper>

								<PostDescription>
									<PostWrapper>
										<PostTitle>{title}</PostTitle>
										<TagContainer>
											{post.category == null ? (
												''
											) : (
													post.category.map((postTag) => (
														<Link to={basePath + 'category/' + postTag.slug}>
															{postTag.title}
														</Link>
													))
												)}
										</TagContainer>
										<VideoLength>&#128336; {videoLengthCalc(post.videoLength)}</VideoLength>

										<PostBody
											dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }}
										/>


										<hr />
										<PostLinks>
											{previous ? (
												<PreviousLink to={`${basePath}/${previous.id}/`}>
													<span>&#8592; Previous</span>
													<h6>{previous.title}</h6>
												</PreviousLink>
											) : (
													<PreviousLink />
												)}
											{next ? (
												<NextLink to={`${basePath}/${next.id}/`}>
													<span>Next &#8594;</span>
													<h6>{next.title}</h6>
												</NextLink>
											) : (
													<NextLink />
												)}
										</PostLinks>

									</PostWrapper>
								</PostDescription>

								<RelatedSection>
									{relatedOnDemand.length > 1 ? (
										<RelatedHeader>YOU MIGHT ALSO LIKE</RelatedHeader>
									) : (
											''
										)}
									<RelatedWrapper display={relatedOnDemand.length > 1 ? true : false}>
										{relatedOnDemand.map(
											({ node: postTag }) =>
												postTag.title == post.title ? (
													''
												) : (
														<RelatedLink
															key={'related' + postTag.category.id}
															to={basePath + postTag.id}
														>
															<Img backgroundColor={cwTheme.lightBlue} sizes={postTag.thumbnail.fluid} />
															{postTag.title}
														</RelatedLink>
													)
										)}
									</RelatedWrapper>
								</RelatedSection>

								<BackToAll>
									<Button
										link={'/ondemand'}
										textColor={cwTheme.white}
										color={cwTheme.secondaryBlue}
										hoverColor={cwTheme.secondaryHover}
										hoverText={cwTheme.white}
									>
										Back to All
									</Button>
								</BackToAll>
							</MetaWrapper>
						</Post>
					</PostContainer>
				</PostSection>

				{/* <SignUpForm header={'Enjoyed this video? Receive the next one in your inbox!'} /> */}
			</Layout>
		</ThemeProvider>
	);
};

export const query = graphql`
	query($slug: String!, $related: [String!]) {
		contentfulOnDemand(id: { eq: $slug }) {
			title
			id
			videoLength
			videoEmbedUrl
			category {
				title
				slug
				id
			}
			publishDate(formatString: "MMMM DD, YYYY")
			description {
				childMarkdownRemark {
					excerpt
					timeToRead
					html
				}
			}
			thumbnail {
				fluid(maxWidth: 800, maxHeight: 500, quality: 100) {
					src
					...GatsbyContentfulFluid_noBase64
				}
			}
		}
		allContentfulOnDemand(limit: 5, filter: { category: { elemMatch: { slug: { in: $related } } } }) {
			edges {
				node {
					title
					id
					category {
						id
					}
					thumbnail {
						fluid(maxWidth: 800, maxHeight: 500, quality: 100) {
							src
							...GatsbyContentfulFluid_noBase64
						}
					}
				}
			}
		}
	}
`;
