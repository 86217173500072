import React from 'react';
import styled from 'styled-components';
import { cwTheme } from '../theme/theme.js';

const EmbedContainer = styled.div`
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

const Video = ({ videoSrcUrl, videoTitle, ...props }) => (
	<EmbedContainer>
		{videoSrcUrl.includes('youtube') ? (
			<iframe
				src={videoSrcUrl}
				title={videoTitle}
				max-width="800px"
				width="100%"
				height="100%"
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				frameBorder="0"
				webkitallowfullscreen="true"
				mozallowfullscreen="true"
				allowFullScreen
			/>
		) : (
			<iframe
				src={videoSrcUrl}
				title={videoTitle}
				max-width="800px"
				width="100%"
				height="100%"
				frameBorder="0"
				webkitallowfullscreen="true"
				mozallowfullscreen="true"
				allowFullScreen
			/>
		)}
	</EmbedContainer>
);
export default Video;
